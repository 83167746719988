import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import classNames from "./enterPinUnlock.module.scss";
import { LOGO_ICON } from "../../config";

const otpRegex = new RegExp(/^\d*$/);
function EnterPinUnlock({ onSucces, onClose, local, label, logoParam }) {
  const [pin, setPin] = useState("");
  const pinValidator = (pinStr) => {
    if (otpRegex.test(pinStr)) setPin(pinStr);
  };
  useEffect(() => {
    if (pin.length === 4) {
      if (local) {
        if (pin === local) {
          try {
            onSucces();
          } catch (error) {}
        } else {
          setPin("");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pin]);
  return (
    <div className={classNames.enterPinUnlock}>
      <div
        className={classNames.overlayClose}
        onClick={() => {
          try {
            onClose();
          } catch (error) {}
        }}
      />
      <div className={classNames.pinCard}>
        <div className={classNames.inCard}>
          <img
            src={logoParam || LOGO_ICON}
            alt=""
            className={classNames.logo}
          />
          <div className={classNames.title}>{label}</div>
          <OtpInput
            containerStyle={classNames.otpInputWrapper}
            value={pin}
            onChange={(otp) => pinValidator(otp)}
            numInputs={4}
            separator={<span> </span>}
            inputStyle={classNames.otpInput}
          />
        </div>
        <div
          className={classNames.btnCloseFooter}
          onClick={() => {
            try {
              onClose();
            } catch (error) {}
          }}
        >
          Close
        </div>
      </div>
    </div>
  );
}

export default EnterPinUnlock;
