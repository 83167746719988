import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "./landingPage.module.scss";
import { FULL_LOGO, LOGO_ICON, MOBILE_ICON } from "../../config";
import EnterPinUnlock from "../../components/EnterPinUnlock/EnterPinUnlock";
import bg from "../../static/images/bg/bg.jpeg";
import ios from "../../static/images/platforms/ios.svg";
import play from "../../static/images/platforms/play.svg";

function LandingPage() {
  const history = useHistory();
  const [admin, setAdmin] = useState(false);
  return (
    <div className={classNames.landingPage}>
      <div className={classNames.content}>
        <img src={bg} alt="" className={classNames.bg} />
        <img className={classNames.logo} src={FULL_LOGO} alt="" />
      </div>
      <div className={classNames.contentMobile}>
        <img className={classNames.logo} src={MOBILE_ICON} alt="" />
        <div className={classNames.btns}>
          <div className={classNames.btnIos}>
            <img src={ios} alt="" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>App Store</div>
            </div>
          </div>
          <div className={classNames.btnAndroid}>
            <img src={play} alt="" className={classNames.icon} />
            <div className={classNames.texts}>
              <div className={classNames.downOn}>Download On</div>
              <div className={classNames.sorce}>Google Play</div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames.footer} onClick={() => setAdmin(true)}>
        <span>Developer Access</span>
      </div>
      {admin && (
        <EnterPinUnlock
          logoParam={LOGO_ICON}
          onSucces={() => {
            history.push("/comingsoon");
            setAdmin(false);
          }}
          onClose={() => setAdmin(false)}
          local={"2222"}
        />
      )}
    </div>
  );
}

export default LandingPage;
